<template>
  <section>
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-custom gutter-b">
          <div class="card-header flex-wrap py-3">
            <div class="card-title">
              <h3 class="card-label">
                Result Settings Catalog
                <span class="d-block text-muted pt-2 font-size-sm"></span>
              </h3>
            </div>

            <div class="card-toolbar">
              <!--begin::Button-->
              <v-file-input
                label="File input"
                variant="outlined"
              ></v-file-input>
              <v-btn class="ml-5" @click.prevent="downloadFile()">
                Download
              </v-btn>
              <!--end::Button-->
            </div>
          </div>
          <div class="card-body">
            <div
              id="kt_datatable_wrapper"
              class="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div class="row">
                <div class="col-sm-12">
                  <v-data-table
                    :headers="headers"
                    :items="catalog_infos"
                    :options.sync="options"
                    :loading="loading"
                    :search="search"
                    class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                  >
                    <template v-slot:item.actions="{ item }">
                      <a href="#" @click.prevent="editItem(item)">
                        Edit
                      </a>
                    </template>
                    <template v-slot:top>
                      <div
                        class="d-flex align-items-center position-relative my-1"
                      >
                        <span
                          class="svg-icon svg-icon-1 position-absolute ms-6"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="17.0365"
                              y="15.1223"
                              width="8.15546"
                              height="2"
                              rx="1"
                              transform="rotate(45 17.0365 15.1223)"
                              fill="black"
                            ></rect>
                            <path
                              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19
                              11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333
                              17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                              fill="black"
                            ></path>
                          </svg>
                        </span>
                        <input
                          type="text"
                          v-model="search"
                          placeholder="Search in keys"
                          label="Search"
                          class="form-control form-control-solid w-250px ps-14"
                        />
                      </div>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      ref="selectedRecordModal"
      id="modal-1"
      title="Detail"
      @ok="saveItem()"
    >
      <form v-if="selectedRecord">
        <div class="form-group">
          <label for="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            v-model="selectedRecord.id"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <v-textarea v-model="selectedRecord.content" outlined></v-textarea>
        </div>
      </form>
    </b-modal>
  </section>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import {
  DOWNLOAD_CATALOG_DATA,
  GET_CATALOG_INFOS,
  LOAD_REDIS_ROW
} from "@/core/services/store/result_settings.module";
import { BModal } from "bootstrap-vue";

export default {
  components: { BModal },

  data() {
    return {
      search: "",
      options: {},
      headers: [
        { text: "Key", value: "key" },
        { text: "Akce", value: "actions" }
      ]
    };
  },
  methods: {
    reloadData() {
      this.$store.dispatch(GET_CATALOG_INFOS);
    },
    editItem(record) {
      this.$store.dispatch(LOAD_REDIS_ROW, record.key).then(() => {
        this.$refs.selectedRecordModal.show();
      });
      this.selectedRecord = record;
    },
    downloadFile() {
      this.$store.dispatch(DOWNLOAD_CATALOG_DATA).then(() => {});
    },
    saveItem() {
      //Validate this.selectedRecord and post to backend, update the original record, hide the modal etc.
      this.$refs.selectedRecordModal.hide();
    }
  },
  watch: {
    changeProject() {
      this.reloadData();
    },
    changeCollection() {
      this.reloadData();
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    changeCollection() {
      return this.selectedCollection.id;
    },
    ...mapState({
      errors: state => state.resultSetting.errors,
      message: state => state.documents.message,
      hasMessage: state => state.resultSetting.hasMessage,
      catalog_infos: state => state.resultSetting.catalog_infos,
      selectedRecord: state => state.resultSetting.catalog_selected,
      loading: state => state.resultSetting.loading
    }),
    ...mapGetters(["selectedCollection", "currentProject"]),
    listHeaders() {
      return Array.from(this.headers).map(a => {
        return {
          text: a.toUpperCase(),
          value: a
        };
      });
    }
  },
  mounted() {
    this.reloadData();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Documents" }]);
  }
};
</script>
